<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title> Claim Reporting ({{ total }})</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu min-width="50%" offset-y :close-on-content-click="false" eager v-model="reportColumnsMenuShown">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }" v-show="!filters">
                <v-icon>mdi-format-columns</v-icon>
              </v-btn>
            </template>
            <span>Report Columns</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>Report Columns</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <SelectAll
                  dense
                  v-model="selectedClaimReportFields"
                  :items="claimReportFields"
                  label="Claim Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 1" class="grey--text caption">
                      ({{ selectedClaimReportFields.length }} claim fields selected)
                    </span>
                  </template>
                </SelectAll>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <SelectAll
                  dense
                  v-model="selectedClaimProductReportFields"
                  :items="claimProductReportFields"
                  label="Product Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  hide-details
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 1" class="grey--text caption">
                      ({{ selectedClaimProductReportFields.length }} product fields selected)
                    </span>
                  </template>

                  ></SelectAll
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <ClaimTypesField
                  dense
                  :messages="['Enables custom claim fields']"
                  v-model="selectedClaimTypes"
                  label="Claim Type"
                  @change="fetchClaimFields"
                  multiple
                  selectAllToggle
                  selectAll
                  @loaded="claimTypesLoaded = true"
                ></ClaimTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <GroupingSelectAll
                  dense
                  :disabled="selectedClaimTypes.length == 0"
                  v-model="selectedClaimFields"
                  :items="claimFields"
                  label="Custom Claim Columns"
                  item-text="name"
                  multiple
                  chips
                  deletable-chips
                  return-object
                  selectAll
                  :messages="selectedClaimTypes.length == 0 ? ['Requires a selected claim type'] : []"
                  @loaded="customClaimColumnsLoaded = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="grey--text caption">
                      ({{ selectedClaimFields.length }} custom claim fields selected)
                    </span>
                  </template>
                </GroupingSelectAll>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item dense class="pa-0">
            <v-list-item-content class="text-right pt-0 pb-0" dense>
              <v-col cols="12" dense class="pt-0 pb-0">
                <v-btn
                  class="primary"
                  @click="
                    updateHeaders();
                    reportColumnsMenuShown = false;
                  "
                  >Apply</v-btn
                >
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-form @submit.stop.prevent="getData" v-show="!filters">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' by ' + $i18n.translate('Keyword')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 115px; display: inline-block" v-bind="attrs" v-on="on">
            <span style="width: 110px">
              <FiscalYearField
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                @inputObj="
                  selectedFiscalYear = $event;
                  if (allLoaded) {
                    getData();
                  }
                "
                @loaded="fiscalYearLoaded = true"
                :client="selectedClient"
                flat
                solo-inverted
                hide-details
                class="mr-2"
                use-store
                v-show="!filters"
              ></FiscalYearField>
            </span>
          </div>
        </template>
        Fiscal Year
      </v-tooltip>

      <v-menu
        min-width="50%"
        offset-y
        :close-on-content-click="false"
        v-model="advancedSearchMenuShown"
        max-height="75vh"
      >
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }" v-show="!filters">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable v-if="selectedClaimTypes.length > 0">
              <v-list-item-content>
                <ClaimStageField
                  dense
                  :messages="['Dependent on claim type']"
                  @selectedStages="selectedClaimStages = $event"
                  label="Claim Stage"
                  :claimTypes="selectedClaimTypes"
                ></ClaimStageField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionTypesField
                  dense
                  hide-details
                  v-model="selectedPromotionTypes"
                  label="Promotion Types"
                ></PromotionTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <PromotionsField
                  dense
                  hide-details
                  v-model="selectedPromotions"
                  label="Promotions"
                  :filters="{ fiscalYear: this.selectedFiscalYear ? this.selectedFiscalYear.fiscalYear : null }"
                  :showKey="true"
                >
                </PromotionsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="!isEngineerOrSalesRep && !isPOOrAdmin">
              <v-list-item-content>
                <OrganizationsField
                  dense
                  hide-details
                  v-model="selectedOrganizations"
                  label="Company Name"
                  :showKey="true"
                ></OrganizationsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="!isEngineerOrSalesRep && !isPOOrAdmin">
              <v-list-item-content>
                <ParticipantsField dense hide-details v-model="selectedParticipants" label="Participant Name">
                  <template v-slot:selectedItemDescription="{ item }">
                    {{ item.fullName }} - {{ item.participantKey }}
                  </template>
                  <template v-slot:itemDescription="{ item }">
                    {{ item.fullName }} - {{ item.participantKey }}
                  </template></ParticipantsField
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedSubmitted"
                  label="Submitted?"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedSubmitted">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Submition Begin Date"
                  v-model="submittedAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedSubmitted">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Submition End Date"
                  v-model="submittedBefore"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedApproved"
                  label="Approved?"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedApproved">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Approval Begin Date"
                  v-model="approvedAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedApproved">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Approval End Date"
                  v-model="approvedBefore"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedRejected"
                  label="Rejected?"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedRejected">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Rejection Begin Date"
                  v-model="rejectedAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedRejected">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Rejection End Date"
                  v-model="rejectedBefore"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  hide-details
                  :items="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false }
                  ]"
                  clearable
                  v-model="selectedClosed"
                  label="Closed?"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedClosed">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Closing Begin Date"
                  v-model="closedAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedClosed">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  label="Closing End Date"
                  v-model="closedBefore"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <AwardVehicleField v-model="selectedAwardVehicle" label="Award Vehicle"> </AwardVehicleField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedAwardVehicle && enabledToFilterByIssuanceDate(selectedAwardVehicle)">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  @input="getData"
                  label="Payment Issuance Begin Date"
                  v-model="paymentIssuanceAfter"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable v-if="selectedAwardVehicle && enabledToFilterByIssuanceDate(selectedAwardVehicle)">
              <v-list-item-content>
                <DateTimePickerField
                  dense
                  hide-details
                  @input="getData"
                  label="Payment Issuance End Date"
                  v-model="paymentIssuanceBefore"
                  defaultTime="00:00"
                ></DateTimePickerField>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item dense class="pa-0">
            <v-list-item-content class="text-right pt-0 pb-0" dense>
              <v-col cols="12" dense class="pt-0 pb-0">
                <v-btn
                  class="primary"
                  @click="
                    updateHeaders();
                    advancedSearchMenuShown = false;
                  "
                  >Apply</v-btn
                >
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            small
            fab
            v-bind="attrs"
            v-on="on"
            @click="onSubmit()"
            :disabled="items.length == 0 || isGeneratingReport"
          >
            <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
            <v-progress-circular indeterminate color="primary" v-if="isGeneratingReport"></v-progress-circular>
          </v-btn>
        </template>
        <span>Export Report ({{ total }}) </span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <DraggableMergingHeaderTable
        fixed-header
        height="50vh"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :expanded.sync="expanded"
        :single-expand="hasProducts"
        :show-expand="hasProducts"
        @item-expanded="fetchClaimProducts"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        selectable
        :server-items-length="total"
        :v-key="items.length + headers.length"
      >
        <template v-for="(header, i) in headersWithoutExpandIcon" v-slot:[`item.${header.value}`]="{ item }">
          <span :key="i">
            <span v-if="header.date === true"
              >{{ resolve(header.value, item) | formatDateClient("MM/DD/YYYY", selectedClient) }}
            </span>
            <span v-else>{{ resolve(header.value, item) }}</span>
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
            <v-data-table
              class="mt-5 mb-5 compact-table"
              :calculate-widths="true"
              v-else
              hide-default-footer
              :headers="selectedClaimProductReportFields"
              :items="expandedClaimProducts[item.id]"
              disable-pagination
            >
            </v-data-table>
          </td>
        </template>
      </DraggableMergingHeaderTable>
    </v-card-text>
  </v-card>
</template>

<script>
import DraggableMergingHeaderTable from "@/gapp-components/components/tables/DraggableMergingHeaderTable.vue";
import AwardVehicleField from "@/gapp-components/components/fields/AwardVehicleField.vue";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import ParticipantsField from "@/gapp-components/components/fields/ParticipantsField.vue";
import OrganizationsField from "@/gapp-components/components/fields/OrganizationsField.vue";
import PromotionsField from "@/gapp-components/components/fields/PromotionsField.vue";
import PromotionTypesField from "@/gapp-components/components/fields/PromotionTypesField.vue";
import ClaimStageField from "@/gapp-components/components/fields/ClaimStageField.vue";
import FiscalYearField from "@/gapp-components/components/fields/FiscalYearField.vue";
import GroupingSelectAll from "@/gapp-components/components/fields/GroupingSelectAll.vue";
import ClaimTypesField from "@/gapp-components/components/fields/ClaimTypesField.vue";
import SelectAll from "@/gapp-components/components/fields/SelectAll.vue";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SelectAll,
    ClaimTypesField,
    GroupingSelectAll,
    FiscalYearField,
    ClaimStageField,
    PromotionTypesField,
    PromotionsField,
    OrganizationsField,
    ParticipantsField,
    DateTimePickerField,
    AwardVehicleField,
    DraggableMergingHeaderTable
  },
  name: "ClaimReporting",
  metaInfo: {
    title: "Claim Reporting"
  },
  data() {
    return {
      valid: false,
      loading: false,
      reportName: "Claim Report",
      expanded: [],
      expandedClaimProducts: [],
      search: "",
      selectedClaimReportFields: [],
      selectedClaimProductReportFields: [],
      selectedClaimFields: [],
      selectedClaimTypes: [],
      selectedOrganizations: [],
      selectedParticipants: [],
      selectedPromotionTypes: [],
      selectedPromotions: [],
      selectedSubmitted: undefined,
      selectedApproved: undefined,
      selectedRejected: undefined,
      selectedClosed: undefined,
      selectedFiscalYear: undefined,
      selectedFiscalQuarter: undefined,
      selectedCurrency: 0,
      approvedBefore: null,
      approvedAfter: null,
      closedBefore: null,
      closedAfter: null,
      rejectedBefore: null,
      rejectedAfter: null,
      submittedBefore: null,
      submittedAfter: null,
      selectedAwardVehicle: undefined,
      selectedClaimStages: [],
      paymentIssuanceBefore: null,
      paymentIssuanceAfter: null,
      fieldsSelected: [],
      filterByClaimType: null,
      filterByPromotionTypes: null,
      claimFields: [],
      claimReportFields: [],
      claimProductReportFields: [],
      claimColumnsLoaded: false,
      productColumnsLoaded: false,
      claimTypesLoaded: false,
      customClaimColumnsLoaded: false,
      fiscalYearLoaded: false,
      reportColumnsMenuShown: false,
      advancedSearchMenuShown: false,
      items: [],
      headers: [],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1
      },
      rules: {
        name: [v => !!v || "Report Name is required"]
      },
      isGeneratingReport: false,
      fiscalQuarters: undefined
    };
  },
  props: {
    claimsEarnings: {
      type: Boolean,
      default: false
    },
    myCompanyClaims: {
      type: Boolean,
      default: true
    },
    myCompanyEarnings: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Object,
      default: null
    }
  },
  methods: {
    resolve(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      let count = 0;
      let reportHeaders = [];
      let sortedHeaders = [...this.headers, ...this.selectedClaimProductReportFields];
      this.isGeneratingReport = true;
      sortedHeaders.sort((a, b) => {
        if (a.enumValue === "BROWSER_REFERER" || a.enumValue === "BROWSER_SOURCE") {
          return 1;
        } else if (b.enumValue === "BROWSER_REFERER" || b.enumValue === "BROWSER_SOURCE") {
          return -1;
        } else {
          return 0;
        }
      });
      sortedHeaders.forEach(header => {
        if (header.name) {
          reportHeaders.push({
            name: header.name,
            enumValue: header.enumValue,
            typeValue: header.typeValue,
            typeId: header.typeId,
            rank: count++
          });
        }
      });
      let postForm = {
        name: this.reportName,
        type: { name: "CLAIMING_MULTIPLE_TYPES" },
        generationType: {
          name: "XLSX"
        },
        filters: this.getFilters(),
        reportHeaders: reportHeaders
      };
      this.$api
        .post("/api/reports/generate", postForm)
        .then(() => {
          this.isGeneratingReport = false;
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          console.log(error);
          this.isGeneratingReport = false;
        });
    },
    updateHeaders() {
      this.$nextTick(() => {
        let sortedClaimFields = this.selectedClaimFields.sort((a, b) => a.rank - b.rank);
        let h = [...this.selectedClaimReportFields, ...sortedClaimFields];
        if (this.hasProducts) {
          h = h.filter(v => v.value != "data-table-expand");
          h.unshift({
            text: "Products",
            value: "data-table-expand",
            class: "data-table-expand-header",
            align: "center"
          });
          this.getData();
          this.$forceUpdate();
        }

        h = h.map(header => this.translateHeader(header));
        h.sort((a, b) => {
          if (a.value === "browserReferer" || a.value === "browserSource") {
            return 1;
          } else if (b.value === "browserReferer" || b.value === "browserSource") {
            return -1;
          } else {
            return 0;
          }
        });
        this.$set(this, "headers", h);
        this.$forceUpdate();
      });
    },
    translateHeader(h) {
      if (h.text) {
        h.text = h.text.replaceAll("Promotion Key", "Promotion Number");
        h.text = h.text.replaceAll("Promotion Name", "Promotion Title");
        h.text = h.text.replaceAll("Promotion Publish Date", "Publish Date");
        h.text = h.text.replaceAll("Promotion Effective Date", "Promotion Start Date");
        h.text = h.text.replaceAll("Promotion Expiration Date", "Promotion End Date");
        h.text = h.text.replaceAll("Company Key", "SAP/Indirect ID");
        h.text = h.text.replaceAll("Participant Key", "Participant ID");
        h.text = h.text.replaceAll("Claim Key", "Claim ID");
        h.text = h.text.replaceAll("Claim Type", "Claim Form Type");
        h.text = h.text.replaceAll("Claim Zero Date", "Zero Sales Date");
        h.text = h.text.replaceAll("Product Key", "Product Number");
        h.text = h.text.replaceAll("Product Serial Number", "Serial Number");
        h.text = h.text.replaceAll("Product Quantity", "Quantity");
        h.text = h.text.replaceAll("Product Payout Award Vehicle", "Product Override Award Vehicle");
        h.text = h.text.replaceAll("Second Custom Field", "6D Tokyo Category");
      }
      if (h.name) {
        h.name = h.name.replaceAll("Promotion Key", "Promotion Number");
        h.name = h.name.replaceAll("Promotion Name", "Promotion Title");
        h.name = h.name.replaceAll("Promotion Publish Date", "Publish Date");
        h.name = h.name.replaceAll("Promotion Effective Date", "Promotion Start Date");
        h.name = h.name.replaceAll("Promotion Expiration Date", "Promotion End Date");
        h.name = h.name.replaceAll("Company Key", "SAP/Indirect ID");
        h.name = h.name.replaceAll("Participant Key", "Participant ID");
        h.name = h.name.replaceAll("Claim Key", "Claim ID");
        h.name = h.name.replaceAll("Claim Type", "Claim Form Type");
        h.name = h.name.replaceAll("Claim Zero Date", "Zero Sales Date");
        h.name = h.name.replaceAll("Product Key", "Product Number");
        h.name = h.name.replaceAll("Product Serial Number", "Serial Number");
        h.name = h.name.replaceAll("Product Quantity", "Quantity");
        h.name = h.name.replaceAll("Product Payout Award Vehicle", "Product Override Award Vehicle");
        h.name = h.name.replaceAll("Second Custom Field", "6D Tokyo Category");
      }
      return h;
    },
    getFilters() {
      let filters = {};

      if (this.selectedParticipant.participantType.participantTypeKey == "300") {
        filters.organizationRelatedParticipantReportToParticipant = { id: this.selectedParticipant.id };
      }

      if (this.selectedParticipant.participantType.participantTypeKey == "400") {
        filters.organizationRelatedParticipant = { id: this.selectedParticipant.id };
      }

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedClaimTypes && this.selectedClaimTypes.length) {
        filters.claimTypes = this.selectedClaimTypes.map(claimType => {
          return { id: claimType.id };
        });
      }

      if (this.selectedPromotions && this.selectedPromotions.length > 0) {
        filters.promotions = this.selectedPromotions.map(promotion => {
          return { id: promotion.id };
        });
      }

      if (this.selectedClaimStages && this.selectedClaimStages.length > 0) {
        filters.claimStages = this.selectedClaimStages.map(claimStage => {
          return { id: claimStage.id };
        });
      }

      if (this.selectedOrganizations && this.selectedOrganizations.length > 0) {
        filters.organizations = this.selectedOrganizations.map(organization => {
          return { id: organization.id };
        });
      }

      if (this.selectedParticipants && this.selectedParticipants.length > 0) {
        filters.participants = this.selectedParticipants.map(participant => {
          return { id: participant.id };
        });
      }

      if (this.selectedSubmitted != undefined) {
        filters.submitted = this.selectedSubmitted;
        filters.submittedBefore = this.submittedBefore;
        filters.submittedAfter = this.submittedAfter;
      }

      if (this.selectedApproved != undefined) {
        filters.approved = this.selectedApproved;
        filters.approvedBefore = this.approvedBefore;
        filters.approvedAfter = this.approvedAfter;
      }

      if (this.selectedRejected != undefined) {
        filters.rejected = this.selectedRejected;
        filters.rejectedBefore = this.rejectedBefore;
        filters.rejectedAfter = this.rejectedAfter;
      }

      if (this.selectedClosed != undefined) {
        filters.closed = this.selectedClosed;
        filters.closedBefore = this.closedBefore;
        filters.closedAfter = this.closedAfter;
      }

      if (this.selectedAwardVehicle != undefined) {
        filters.awardVehicle = { id: this.selectedAwardVehicle.id };

        filters.paymentIssuanceBefore = this.paymentIssuanceBefore;
        filters.paymentIssuanceAfter = this.paymentIssuanceAfter;
      }
      if (this.selectedFiscalYear) {
        filters.fiscalYear = this.selectedFiscalYear.fiscalYear;
      }

      if (this.selectedFiscalQuarter) {
        filters.invoiceDateAfter = this.selectedFiscalQuarter.start;
        filters.invoiceDateBefore = this.selectedFiscalQuarter.end;
      }

      if (this.selectedCurrency) {
        filters.currency = {
          name: this.selectedCurrency == "0" ? "USD" : "CAD"
        };
      }

      if (this.selectedPromotionTypes && this.selectedPromotionTypes.length > 0) {
        filters.promotionTypes = this.selectedPromotionTypes.map(promotionType => {
          return { id: promotionType.id };
        });
      }

      if (this.filterByPromotionTypes && this.filterByPromotionTypes.length > 0) {
        filters.promotionTypes = this.filterByPromotionTypes.map(promotionTypeId => {
          return {
            id: promotionTypeId
          };
        });
      }

      if (this.isPOOrAdmin) {
        filters.organization = { id: this.selectedParticipant.organization.id };
      }

      if (this.isEngineerOrSalesRep) {
        filters.participant = { id: this.selectedParticipant.id };
      }

      return filters;
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = this.getFilters();
      this.$api
        .post(
          "/api/claims/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.items.reduce((p, item) => this.fetchClaimFieldValues(item), Promise.resolve());
          this.items = this.items.map(c => {
            c.claimStage.name = this.$util.formatStage(c.claimStage, c);
            return c;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchClaimFieldValues(item) {
      return this.$api.get("/api/claimFieldValues/byClaimId/" + item.id + "?size=100").then(({ data }) => {
        data.content.forEach(claimFieldValue => {
          let value = claimFieldValue.value;
          if (claimFieldValue.claimField.claimFieldType.date === true) {
            value = this.$util.formatDateClient(value, "MM/DD/YYYY", this.selectedClient);
          }
          Vue.set(item, "claimField_" + claimFieldValue.claimFieldId, value);
        });
        return Promise.resolve(item);
      });
    },
    fetchClaimReportFields() {
      this.claimReportFields = [];
      return this.$api.get("/api/types/report/claimReportFields").then(({ data }) => {
        this.claimReportFields = [];
        data.forEach(field => {
          field.description = field.description.replaceAll("Organization", "Company");
          this.claimReportFields.push({
            name: field.description,
            enumValue: field.name,
            typeValue: "ClaimReportFieldType",
            value: field.vueValue,
            align: field.align ? field.align.toLowerCase() : "left",
            sortable: false,
            text: field.description,
            numeric: field.numeric,
            date: field.date
          });
        });
        this.selectedClaimReportFields = this.claimReportFields;
        this.claimColumnsLoaded = true;
        this.fetchClaimProductReportFields();
      });
    },
    fetchClaimProductReportFields() {
      this.claimProductReportFields = [];
      return this.$api.get("/api/types/report/claimProductReportFields").then(({ data }) => {
        this.claimProductReportFields = [];
        data
          .filter(elem => elem.name != "FIRST_CUSTOM_FIELD" && elem.name != "THIRD_CUSTOM_FIELD")
          .forEach(field => {
            field.description = field.description.replaceAll("Organization", "Company");
            this.claimProductReportFields.push({
              name: field.description,
              enumValue: field.name,
              typeValue: "ClaimProductReportFieldType",
              value: field.vueValue,
              align: field.align ? field.align.toLowerCase() : "left",
              sortable: false,
              text: field.description,
              numeric: field.numeric,
              date: field.date
            });
          });
        this.claimProductReportFields.splice(
          this.claimProductReportFields.findIndex(elem => elem.enumValue == "TOTAL_PAYOUT_AMOUNT") + 1,
          0,
          this.claimProductReportFields.splice(
            this.claimProductReportFields.findIndex(elem => elem.enumValue == "PRODUCT_PAYOUT_CURRENCY"),
            1
          )[0]
        );
        this.claimProductReportFields = this.claimProductReportFields.map(header => this.translateHeader(header));
        this.selectedClaimProductReportFields = this.claimProductReportFields;
        this.productColumnsLoaded = true;
        // this.updateHeaders();
      });
    },
    fetchClaimFields() {
      this.selectedClaimFields = [];
      this.claimFields = [];
      let localClaimFields = []; //needed this to avoid changing ClaimTypesField emission of events (emits twice when checking an item, and race condition occurs), and didn't know the effects of such change
      if (this.selectedClaimTypes && this.selectedClaimTypes.length > 0) {
        let promises = [];
        for (let claimType of this.selectedClaimTypes) {
          promises.push(
            this.$api
              .get("/api/claimFields/byClaimTypeId/" + claimType.id + "?size=100&sort=rank,ASC")
              .then(({ data }) => {
                data.content.forEach(field => {
                  localClaimFields.push({
                    name: field.name,
                    value: "claimField_" + field.id,
                    rank: field.rank,
                    typeValue: "ClaimField",
                    typeId: field.id,
                    align: field.claimFieldType.align,
                    sortable: false,
                    text: field.name,
                    claimType: field.claimType.name
                  });
                });
              })
          );
        }

        Promise.all(promises).then(() => {
          this.claimFields = localClaimFields;
        });
      }
    },
    fetchClaimProducts(obj) {
      if (obj.item.id in this.expandedClaimProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/claimProducts/byClaimId/" + obj.item.id)
        .then(({ data }) => {
          this.expandedClaimProducts[obj.item.id] = data.content;
          for (let elem of this.expandedClaimProducts[obj.item.id]) {
            elem.promotion = obj.item.promotion;

            if (elem.purchaseDate) {
              elem.purchaseDate = this.$util.formatDateClient(elem.purchaseDate, "MM/DD/YYYY", this.selectedClient);
            }

            if (elem.payouts[0] && elem.payouts[0].bankTransaction) {
              if (elem.payouts[0].bankTransaction.issuedDate) {
                elem.payouts[0].bankTransaction.issuedDate = this.$util.formatDateClient(
                  elem.payouts[0].bankTransaction.issuedDate,
                  "MM/DD/YYYY",
                  this.selectedClient
                );
              }
              if (elem.payouts[0].bankTransaction.createdDate) {
                elem.payouts[0].bankTransaction.createdDate = this.$util.formatDateClient(
                  elem.payouts[0].bankTransaction.createdDate,
                  "MM/DD/YYYY",
                  this.selectedClient
                );
              }
              if (elem.payouts[0].bankTransaction.creditMemoDate) {
                elem.payouts[0].bankTransaction.creditMemoDate = this.$util.formatDateClient(
                  elem.payouts[0].bankTransaction.creditMemoDate,
                  "MM/DD/YYYY",
                  this.selectedClient
                );
              }
              if (
                elem.payouts[0].bankTransaction.referenceBankTransaction &&
                elem.payouts[0].bankTransaction.referenceBankTransaction.achDate
              ) {
                elem.payouts[0].bankTransaction.referenceBankTransaction.achDate = this.$util.formatDateClient(
                  elem.payouts[0].bankTransaction.referenceBankTransaction.achDate,
                  "MM/DD/YYYY",
                  this.selectedClient
                );
              }
              if (
                elem.payouts[0].bankTransaction.referenceBankTransaction &&
                elem.payouts[0].bankTransaction.referenceBankTransaction.checkDate
              ) {
                elem.payouts[0].bankTransaction.referenceBankTransaction.checkDate = this.$util.formatDateClient(
                  elem.payouts[0].bankTransaction.referenceBankTransaction.checkDate,
                  "MM/DD/YYYY",
                  this.selectedClient
                );
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDescendantProp(obj, desc) {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    enabledToFilterByIssuanceDate(awardVehicle) {
      let awardVehicleType = awardVehicle.awardVehicleType;
      if (
        awardVehicleType &&
        awardVehicleType.name &&
        (awardVehicleType.name == "MANUAL" ||
          awardVehicleType.name == "CHECKISSUING_ACH" ||
          awardVehicleType.name == "CHECKISSUING_CHECK" ||
          awardVehicleType.name == "CREDIT_MEMO")
      ) {
        return true;
      } else {
        return false;
      }
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Reports"
      },
      { text: "Claiming Report" }
    ]);

    this.fetchClaimReportFields();
  },
  watch: {
    options: {
      handler() {
        if (this.allLoaded) {
          this.getData();
        }
      },
      deep: true
    },
    allLoaded(v) {
      if (v) {
        this.updateHeaders();
      }
    },
    selectedFiscalYear: {
      deep: true,
      handler() {
        this.fiscalQuarters = this.$client.getFiscalQuartersByYear(
          this.selectedClient,
          this.selectedFiscalYear.fiscalYear
        );

        this.getData();
      }
    },
    selectedFiscalQuarter: {
      deep: true,
      handler() {
        this.getData();
      }
    },
    selectedCurrency: {
      deep: true,
      handler() {
        this.getData();
      }
    },
    filters: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          this.selectedFiscalYear = this.filters.selectedFiscalYear ? this.filters.selectedFiscalYear : null;
          this.fiscalQuarters = this.$client.getFiscalQuartersByYear(
            this.selectedClient,
            this.selectedFiscalYear.fiscalYear
          );

          if (this.filters.selectedFiscalQuarter) {
            this.selectedFiscalQuarter = this.fiscalQuarters[this.filters.selectedFiscalQuarter];
          }
          this.selectedCurrency = this.filters.selectedCurrency ? this.filters.selectedCurrency : null;

          this.selectedPromotions = this.filters.promotionId ? [{ id: this.filters.promotionId }] : null;
          this.selectedApproved = this.filters.selectedApproved ? this.filters.selectedApproved : null;
          this.selectedOrganizations = this.filters.selectedOrganizations ? this.filters.selectedOrganizations : null;
          this.selectedParticipants = this.filters.selectedParticipants ? this.filters.selectedParticipants : null;
        }
      }
    },
    selectedApproved: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.approvedAfter = null;
          this.approvedBefore = null;
        }
      }
    },
    selectedSubmitted: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.submittedAfter = null;
          this.submittedBefore = null;
        }
      }
    },
    selectedRejected: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.rejectedAfter = null;
          this.rejectedBefore = null;
        }
      }
    },
    selectedClosed: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.closedAfter = null;
          this.closedBefore = null;
        }
      }
    },
    selectedAwardVehicle: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.paymentIssuanceAfter = null;
          this.paymentIssuanceBefore = null;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),
    hasProducts() {
      return this.selectedClaimProductReportFields?.length > 0;
    },
    isPOOrAdmin() {
      let participantTypesKeys = ["500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"];
      return participantTypesKeys.includes(this.selectedParticipant.participantType.participantTypeKey);
    },
    isEngineerOrSalesRep() {
      let POOorAdmin = ["500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"];
      let admins = ["100", "200", "300", "400"];
      return (
        !POOorAdmin.includes(this.selectedParticipant.participantType.participantTypeKey) &&
        !admins.includes(this.selectedParticipant.participantType.participantTypeKey)
      );
    },
    allLoaded() {
      return (
        this.claimColumnsLoaded &&
        this.productColumnsLoaded &&
        this.claimTypesLoaded &&
        this.customClaimColumnsLoaded &&
        this.fiscalYearLoaded
      );
    },
    headersWithoutExpandIcon() {
      return this.headers.filter(elem => elem.value != "data-table-expand");
    }
  }
};
</script>
